$(function() {
    $('.collapsible').collapsible();
    $('.user-info').userInfo();
    $(".datalist:not(.no-sort)").tablesorter({
        sortLocaleCompare: true
    });
    $('[data-dropdown-template]').tooltipDropdown();

    $('input, form').each(function() {
        var $this = $(this);

        $this.attr('autocomplete', $this.attr('autocomplete') || 'chrome-off');
    });
    $('input[type="password"]').attr('autocomplete', 'new-password');

    // Highlightable tables
    $(document).on('click', 'table.highlight tr', function () {
        $(this).parent().find('tr').removeClass('selected');
        $(this).addClass('selected');
    });

    // Empty inputs
    $(document).on('input blur', 'input, textarea', function() {
        $(this).toggleClass('empty', $(this).val() === '');
    });
    $('input, textarea').trigger('blur');

    // Only numeric and don't allowing 'e' key
    $(document)
        .on('keypress', 'input[type="number"]', function(e) {
            return e.keyCode !== 69 && e.keyCode !== 101;
        })
        .on('paste', 'input[type="number"]', function(e) {
            return e.originalEvent.clipboardData.getData('Text').indexOf('e') === -1
                && e.originalEvent.clipboardData.getData('Text').indexOf('E') === -1;
        });

    $('#btnCloseMenu').click(function() {
        enableBodyScroll();
        $('#myNav').css('left', '-100%')
            .find('.collapsible').collapsible('close');
    });
    $('#btnMobileMenu').click(function() {
        disableBodyScroll();
        $('#myNav').css('left', '0px');
    });

    /* Scrollbars */
    window.scrollbars = $('.dlg-modal-content, .split-panel, .datapanel-content, .tippy-content, body');
    window.scrollbars = window.scrollbars.overlayScrollbars($.OPTIONS.scrollbars).overlayScrollbars();

    setTimeout(function() {
        $('.stickyheader').stickyHeader({
            offset: $('nav').outerHeight(true),
        });
    }, 1500);

    $('#ddYears > li[data-year]').click(function(e) {
        e.stopImmediatePropagation();

        REST.patch('/year/change/' + $(this).attr('data-year'))
            .success(function (data) {
                location.reload();
            });
    });

    Utils.initSelects();

    $(document)
        .on('dialog.shown', function(e, dlg) {
            Utils.initSelects($.extend({}, Utils.SELECT2_OPTIONS, { dropdownParent: $(dlg) }), $(dlg).find('select'));
        })
        /*.on('select2:open', 'select', function() {
            $('.select2-container--open').css('pointer-events', 'none');

            setTimeout(function() {
                $('.select2-container--open').css('pointer-events', 'auto');
            }, 100);
        })*/
        .on('keydown', '.select2-selection', function(e) {
            var $select = $(this).closest('.select2').prev('select');

            if (e.keyCode === 38) {
                e.preventDefault();
                $select.find(':selected').prev().prop('selected', true);
                $select.trigger('change');
            }
            if (e.keyCode === 40) {
                e.preventDefault();
                $select.find(':selected').next().prop('selected', true);
                $select.trigger('change');
            }
        })
        .on('keypress', '.select2-selection', function(e) {
            if ([127].indexOf(e.keyCode) > -1)
                return;

            $(this).closest('.select2').prev('select').select2('open');
            $('.select2-search__field').focus().trigger(jQuery.Event('keypress', { keycode: e.keyCode }));
        })
        .on('select2:clear', 'select', function(e) {
            $(this).one('select2:opening', function(e) {
                e.preventDefault();
            });
        })

    /*var prevScrollpos = window.pageYOffset;
    window.onscroll = function() {
        var currentScrollPos = window.pageYOffset;
        if (prevScrollpos > currentScrollPos) {
            $(".nav-bar")[0].style.top = "0";
        } else {
            $(".nav-bar")[0].style.top = "-42px";
        }
        prevScrollpos = currentScrollPos;
    }*/

    $('body').on('keydown', function(e) {
        if (e.keyCode === 120 && e.altKey && $('#snake-game').length === 0) {
            $('body').append('<div id="snake-game"><div class="gamearea"><div class="score" id="score">0</div><canvas id="canvas" height="500px" width="500px"></canvas></div></div>');
            $('head').append($('<link rel="stylesheet" type="text/css" href="/css/snake.css">').on('load', function() {
                $.getScript('/js/snake.min.js');
            }));
        }
    });
});

var _i = 0;

$.OPTIONS = {
    scrollbars: {
        scrollbars: {
            autoHide: 'move',
            clickScrolling: true
        },
        updateOnLoad: ['.split-panel, .tippy-content']
    }
}